import React from "react";
// import './App.css';

function App() {
  return ( 
  
  <div className="container w-container">
    <div className="w-layout-grid grid">
      <div id="w-node-_39327cb4-68a7-3f87-45e1-59f6d457e3e5-3911144e" className="logo-div">
        <img src="images/kuva-logo-taline-rev.svg" loading="lazy" width="160" alt="" class="logo"/>
        </div>
        <div className="content-div">
          <div className="form-block w-form">
        <form id="email-form" name="email-form" data-name="Email Form" className="form">
          <label for="name" className="text-field-label">Username</label>
          <input type="text" className="text-field w-input" maxlength="256" name="name" data-name="Name" placeholder="" id="name"/>
            <label for="email" class="text-field-label">Password</label>
            <input type="email" class="text-field-2 w-input" maxlength="256" name="email" data-name="Email" placeholder="" id="email" required=""/>
            <input type="submit" value="Submit" data-wait="Please wait..." class="primary-button w-button"/>
          <p className="description">Phasellus eu venenatis augue, eget accumsan lorem. Vestibulum at augue urna? </p>
          <p className="text-link">Contact us</p>
        </form>
        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}

export default App;